let awsRegion = 'ap-southeast-2';

export default {
 api_urls: {
   reporting: 'https://5rdp2cr4ul.execute-api.ap-southeast-2.amazonaws.com/api',
   geojson: 'https://bgnulai364.execute-api.ap-southeast-2.amazonaws.com/api',
   overlays: 'https://qllufu06cf.execute-api.ap-southeast-2.amazonaws.com/api',
 },
 google_api_url: 'AIzaSyDI0B09O3FFEn1-usWMdtJAzat9O7SbvR4',
 main_company_term_id: 6,
 crm_base_url: 'https://crm.ozminerals.forwoodsafety.com',
 forwood_id_url: 'https://id.ozminerals.forwoodsafety.com?redirect_uri=https://geoeditor.ozminerals.forwoodsafety.com',
 custom_tile_url: 'https://mapoverlays.geoeditor.ozminerals.forwoodsafety.com',
 Auth: {
   userPoolId: 'ap-southeast-2_8ygXVgFwA',
   userPoolWebClientId: '6kg1lq2c6c8g9ecn3vfan6op8t',
   cookieStorage: {
     domain: '.ozminerals.forwoodsafety.com',
     secure: true,
   },
 },
 intercom_enabled: 'False',
};
